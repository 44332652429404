// CSS responsible for setting root level colour variables

// Do not use these colours outside of this file
:root {
    --grey-dark: #444444;
}

// See app.js for the assignation of root's className 
:root.riskaware {
    --app-header-background: var(--grey-dark);
    --app-footer-background: var(--grey-dark);
}
:root.smiths {
    --app-header-background: black;
    --app-footer-background: black;
}