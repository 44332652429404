@import '../../../colours';

@font-face {
    font-family: "montserrat";
    src: url("../../common/Montserrat-Medium.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.ua-tab.account .ui.tabular.menu .item {
    max-width: 100px;
}

.ui.form.ua-form .field>.ui.inline.dropdown>.dropdown.icon {
    top: 0;
    right: 0;
}

.ui.form.ua-form .field>.ui.inline.dropdown>.text {
    font-weight: 100;
}

.ui.form.ua-form .field>.ui.selection.dropdown .menu>.item {
    color: black !important;
}

.aois-display {
    min-width: 300px;
}

.ui.form.ua-form {
    margin-top: 1em;
    color: black;
}

.ui.form.ua-form .fields {
    width: 100%;
    margin: 0em 0em 0.5em;
    font-family: "montserrat";
    font-size: small;
}

.ui.form.ua-form .fields>.field {
    width: 100%;
}

.ui.form.ua-form .fields .field {
    width: 100%;
    padding: 0;
}

.ui.form.ua-form .fields .inline.field>label {
    width: 34%;
}

.ui.form.ua-form .fields .field>label {
    width: 100%;
    margin: 0;
}

.ui.form.ua-form .fields .field>.selection.dropdown {
    width: 66%;
}

.ui.form.ua-form .separator {
    border-top: 2px solid $primary-dark;
    margin: 0px -10px;
}

.ui.form.ua-form .fields .field.absolute>.selection.dropdown {
    position: absolute;
}

.ui.form.ua-form .field>.selection.dropdown {
    border: 1px solid $light-gold !important;
}

.ui.form.ua-form .fields .field>.ui.input {
    width: 100%;
    font-family: "montserrat" !important;
}

.ui.form.ua-form .fields .field>.ui.input input {
    width: inherit;
    font-family: "montserrat" !important;
}

.ui.form.ua-form .fields .inline.field .ui.input {
    width: 66% !important;
    display: inline;
}


.ui.form.ua-form .fields .inline.field .ui.input.radio {
    display: inline-block;
}

.ui.form.ua-form .fields .inline.field .ui.input.input-bar {
    width: 44%;
    display: inline-flex;
}

.ui.form.ua-form .fields .inline.field textarea {
    resize: none;
    font-family: "montserrat" !important;
    border: 1px solid $light-gold !important;
}

.ui.form.ua-form .fields .inline.field textarea[readonly] {
    border: 1px solid $pale-gold !important;
    cursor: default;
}

.ui.form.ua-form .fields.location-field {
    display: block;
}

.ui.form.ua-form input {
    border: 1px solid $light-gold !important;
}

.ui.form.ua-form input[readonly] {
    border: 1px solid $pale-gold !important;
    cursor: default;
}

.ui.form.ua-form .fields .inline.field .ui.input.input-bar input {
    width: 44%;
    font-family: "montserrat" !important;
}

.ui.checkbox label {
    font-weight: 400;
    font-family: "montserrat";
    color: $primary-dark !important;
}

.ua-form .error.field input {
    border: 2px solid #e48611 !important;
    font-family: "montserrat" !important;
}

.ui.form.ua-form .fields.error .field .ui.dropdown .item,
.ui.form.ua-form .field.error .ui.dropdown .text,
.ui.form.ua-form .field.error .ui.dropdown .item {
    background: none;
    color: black;
}

.ui.form .error.field .ui.prompt.label {
    border: none !important;
    margin-top: 0 !important;
}

.ui.form .error.field .ui.prompt.label:before {
    content: none
}