@import "../../../../colours.scss";

.ui.accordion.audit-accordion {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    flex-direction: column;
    padding: 5px;
}

.ui.accordion.audit-accordion::-webkit-scrollbar {
    width: 5px;
}

.ui.accordion.audit-accordion::-webkit-scrollbar-thumb {
  background-color: $primary-dark;
}

.ui.accordion.audit-accordion .title:not(.ui) {
    color: black;
}

.ui.accordion.audit-accordion .content {
    color: black;
}

.ui.table.audit-change-table, .ui.table.audit-change-table th {
    color: black;
    line-height: initial;
}

.ui.table.audit-change-table td {
    width: 1%;
    text-align: end;
}
.ui.table.ui.table.audit-change-table td.prev-value-cell {
    text-decoration-line: line-through;
}

.content-body.audits-panel {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.content-body.audits-panel .options {
    display: flex;
    flex-direction: column;
}

.audits-panel .audits-footer {
    color: black;
    display: flex;
    justify-content: flex-end;
    padding: 0 5px 0 5px;
    font-size: smaller;
}

.audits-panel .ui.checkbox {
    padding: 15px;
}

.audit-header {
    display: flex;
    flex-direction: row;
}

.audit-time {
    color: grey;
    flex: 1;
}

.audit-user {
    color: grey;
    flex: 1;
    text-align: end;
}
