.ui.form.ua-inline-form .fields {
  flex-direction: column;
}

.ui.form.ua-inline-form .fields .inline.field {
  margin-bottom: 5px;
}

.ui.form.ua-inline-form .fields .error.inline.field .ui.pointing.above.prompt.label{
  position: inherit;
}